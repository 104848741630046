<template>
<footer>
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="footer-manu">
						<ul>
							<li><a href="contact.html">联系我们</a></li>
							<li><a href="cloud.html">云手机</a></li>
							<li><a href="inf.html">网红营销</a></li>
							<li><a href="browser.html">指纹浏览器</a></li>
							<li><a href="news.html">行业动态</a></li>

						</ul>
					</div>
					<p>Copyright &copy; 2016.WanHe All rights reserved</p>
				</div>
			</div>
		</div>
	</footer>
</template>
<style scoped>

</style>