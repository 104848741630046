import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/infl',
    name: 'infl',
    component: () => import('@/views/infl.vue')
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import('@/views/ad.vue')
  },
  {
    path: '/seo',
    name: 'seo',
    component: () => import('@/views/seo.vue')
  },
  {
    path: '/sem',
    name: 'sem',
    component: () => import('@/views/sem.vue')
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('@/views/phone.vue')
  },
  {
    path: '/browser',
    name: 'browser',
    component: () => import('@/views/browser.vue')
  },
  {
    path: '/cloud',
    name: 'cloud',
    component: () => import('@/views/cloud.vue')
  },
  {
    path: '/fin',
    name: 'fin',
    component: () => import('@/views/fin.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/article.vue'),
  },
  {
    path: '/a1',
    name: 'a1',
    component: () => import('@/views/articles/a1.vue')
  },
  {
    path: '/a2',
    name: 'a2',
    component: () => import('@/views/articles/a2.vue')
  },
  {
    path: '/a3',
    name: 'a3',
    component: () => import('@/views/articles/a3.vue')
  },
  {
    path: '/a4',
    name: 'a4',
    component: () => import('@/views/articles/a4.vue')
  },
  {
    path: '/a5',
    name: 'a5',
    component: () => import('@/views/articles/a5.vue')
  },
  {
    path: '/a6',
    name: 'a6',
    component: () => import('@/views/articles/a6.vue')
  },
  {
    path: '/a7',
    name: 'a7',
    component: () => import('@/views/articles/a7.vue')
  },
  {
    path: '/a8',
    name: 'a8',
    component: () => import('@/views/articles/a8.vue')
  },
  {
    path: '/a9',
    name: 'a9',
    component: () => import('@/views/articles/a9.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

// 或
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
