<template>
	<!-- Header Start -->
	<header>
		<div class="container" >
			<div class="row"  >
				<div class="col-md-12">
					<!-- header Nav Start -->
					<nav class="navbar navbar-default">
						<div class="container-fluid">
							<!-- Brand and toggle get grouped for better mobile display -->
							<div class="navbar-header">
								<button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
									data-target="#bs-example-navbar-collapse-1">
									<span class="sr-only">Toggle navigation</span>
									<span class="icon-bar"></span>
									<span class="icon-bar"></span>
									<span class="icon-bar"></span>
								</button>
								<a class="navbar-brand" href="index.html">
									<img src="../assets/img/logo.png" alt="" srcset="" width="60px">
									<span>博达国际</span>
								</a>
							</div>
							<!-- Collect the nav links, forms, and other content for toggling -->
							<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
								<ul class="nav navbar-nav navbar-right">
									<li>
                                        <router-link to="/"> 首页</router-link></li>
									<li class="dropdown">
										<a href="" class="nav-link dropdown-toggle" id="navbarDropdown"
											data-toggle="dropdown">营销方案 <span class="caret"></span></a>
										<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
											<li><router-link to="/infl"> 社媒网红营销推广</router-link></li>
											<li><router-link to="/ad"> 主流媒体广告投放</router-link></li>
											<li><router-link to="/seo"> 谷歌 seo</router-link></li>
											<li><router-link to="/sem"> 谷歌 sem</router-link></li>
											

										</ul>
									</li>
									<li class="dropdown">
										<a href="" class="nav-link dropdown-toggle" id="navbarDropdown"
											data-toggle="dropdown">工具服务 <span class="caret"></span></a>
										<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><router-link to="/phone"> 海外云手机</router-link></li>
                                            <li><router-link to="/browser"> 指纹浏览器</router-link></li>
                                            <li><router-link to="/cloud"> 云服务器</router-link></li>
										</ul>
									</li>
									<!-- <li><router-link to="/fin"> 跨境金融支持</router-link></li> -->
									<!-- <li><a href="financial.html">跨境金融支持</a></li> -->
									<li><router-link to="/article"> 行业动态</router-link></li>
									<!-- <li><a href="#">行业动态</a></li> -->
									<li><router-link to="/contact"> 联系我们</router-link></li>
									<!-- <li><a href="contact.html">联系我们</a></li> -->
								</ul>
							</div><!-- /.navbar-collapse -->
						</div><!-- /.container-fluid -->
					</nav>
				</div>
			</div>
		</div>
	</header><!-- header close -->
</template>
<style>
header{
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100vw;
}
header {
  background: #ffffffb8;
  padding: 20px 0;
  backdrop-filter: blur(30px);
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.183); */
}
header .navbar {
  margin-bottom: 0px;
  border: 0px;
}
header .navbar-brand {
width: 200px;
padding: 0;
display: flex;
align-items: center;
gap: 10px;
}
.navbar-brand span{
  font-weight: bolder;
  color: #363636;
  letter-spacing: 5px;
  font-size: 20px;
}
.navbar-brand img{
  width: 50px!important;
}
header .navbar-default {
  background: none;
  border: 0px;
}
header .navbar-default .navbar-nav {
  padding-top: 5px;
}
header .navbar-default .navbar-nav li a {
  color: #333333;
  padding: 10px 26px;
  font-size: 15px;
}
font header .navbar-default .navbar-nav li a:hover {
  color: #000;
}
</style>